<template>
  <div>
    <div class="bannerContainer">
      <div class="w" :class="$i18n.locale == 'en' ? 'en_banner' : 'zh_banner'">
        <div class="title">{{ $t('AI智能识别算法') }}</div>
        <div class="info">
          {{
            $t('自主研发多种类型AI视觉识别核心算法，满足各种行业视觉识别需求。')
          }}
        </div>
      </div>
    </div>

    <div
      :class="
        $i18n.locale == 'en'
          ? 'en_banner_bottom_Container'
          : 'zh_banner_bottom_Container'
      "
    >
      <div class="banner_bottom w">
        <div class="top">
          {{
            $t(
              '赋能软件服务商，集成AI视觉识别核心算法，快速实现各行业AI视觉识别的智能化应用方案。'
            )
          }}
        </div>
        <div class="left">{{ $t('提供SDK、HTTP API等多种集成方式。') }}</div>
        <div class="right">
          {{ $t('落地边缘计算实际应用，支持实时数据分析，持续迭代优化。') }}
        </div>
      </div>
    </div>

    <div class="info_box">
      <div class="industryApplicationContainer">
        <div class="industryApplication w">
          <div class="title">{{ $t('行业应用') }}</div>
          <div class="img">
            <img src="../../../assets/AI智能识别算法/行业配图.png" alt="" />
          </div>
          <div class="item_left">
            <div class="item_title">{{ $t('生鲜领域') }}</div>
            <div class="item_info">
              {{
                $t(
                  '提供Windows系统和安卓系统的完整解决方案，包括：OSAI POS Plugin、OSAI ScaleAI。'
                )
              }}
            </div>
          </div>
          <div class="item_top">
            <div class="item_title">{{ $t('零食行业') }}</div>
            <div class="item_info">
              {{
                $t(
                  '针对零食行业自主研发对应的AI视觉识别核心算法，提供完整的软硬件解决方案。'
                )
              }}
            </div>
          </div>
          <div class="item_bottom">
            <div>{{ $t('烘焙行业，团餐行业，') }}</div>
            <div>{{ $t('农贸市场，政务执行和治理等。') }}</div>
          </div>
        </div>
      </div>
      <div
        ref="AIAlgorithm"
        id="AIAlgorithm"
        :class="$i18n.locale == 'en' ? 'en_AIAlgorithm' : 'zh_AIAlgorithm'"
      >
        <div class="title">{{ $t('AI生鲜识别算法') }}</div>
      </div>
      <div class="video">
        <video
          src="https://dl.tx.cdn.osai.cc/web/media/3月19日.mp4"
          muted
          autoplay
          loop
        ></video>
      </div>
      <div
        ref="AISnack"
        id="AISnack"
        :class="$i18n.locale == 'en' ? 'en_AISnack' : 'zh_AISnack'"
      >
        <div class="title">{{ $t('AI零食识别算法') }}</div>
      </div>
      <div
        ref="AIBaking"
        id="AIBaking"
        :class="$i18n.locale == 'en' ? 'en_AIBaking' : 'zh_AIBaking'"
      >
        <div class="title">{{ $t('AI烘焙识别算法') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$nextTick(() => {
      this.toLocal()
    })
  },
  methods: {
    toLocal() {
      //查找存储的锚点id
      let Id = this.$route.query.anchor
      // let headerHeight = localStorage.getItem('headerHeight')
      // let toElement = document.getElementById(Id)
      //锚点存在跳转
      if (this.$route.query.anchor) {
        const top1 = this.$refs[Id].getBoundingClientRect().top
        // 获取滚动条的位置
        const top2 =
          document.body.scrollTop || document.documentElement.scrollTop
        // 计算元素距视窗的实际高度
        const top = top1 + top2
        // 滚动到相应位置
        window.scrollTo(0, top - 80)
        // toElement.scrollIntoView()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.bannerContainer {
  background: url(../../../assets/AI智能识别算法/首页配图.png) no-repeat center;
  background-size: cover;
  .zh_banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    .title {
      line-height: 104.26px;
      color: #344f98;
      font-size: 72px;
      font-weight: 700;
    }
    .info {
      width: 642px;
      color: #808080;
      font-size: 24px;
      height: 83px;
      font-size: 24px;
      line-height: 34.75px;
    }
  }
  .en_banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    .title {
      line-height: 65.16px;
      color: #344f98;
      font-size: 45px;
      font-weight: 700;
    }
    .info {
      width: 623px;
      color: #808080;
      font-size: 24px;
      font-size: 24px;
      line-height: 34.75px;
    }
  }
}
.zh_banner_bottom_Container {
  background: url(../../../assets/AI智能识别算法/配图.png) no-repeat center;
  background-size: cover;
  .banner_bottom {
    position: relative;
    height: 860px;
    font-size: 20px;
    line-height: 30px;
    color: #383838;
    .top {
      top: 144px;
      left: 128px;
      position: absolute;
      width: 432px;
    }
    .left {
      bottom: 132px;
      left: 128px;
      position: absolute;
      width: 432px;
    }
    .right {
      top: 666px;
      right: 106px;
      position: absolute;
      width: 432px;
    }
  }
}
.en_banner_bottom_Container {
  background: url(../../../assets/AI智能识别算法/配图en.png) no-repeat center;
  background-size: cover;
  .banner_bottom {
    position: relative;
    height: 860px;
    font-size: 20px;
    line-height: 30px;
    color: #383838;
    .top {
      top: 127px;
      left: 97px;
      position: absolute;
      width: 469px;
    }
    .left {
      bottom: 131px;
      left: 100px;
      position: absolute;
      width: 432px;
    }
    .right {
      top: 651px;
      right: 106px;
      position: absolute;
      width: 459px;
    }
  }
}

.info_box {
  .title {
    text-align: center;
    font-weight: 700;
    line-height: 52.13px;
    font-size: 36px;
    color: #344f98;
  }
  .industryApplicationContainer {
    background: #edf3ff;
    .industryApplication {
      height: 732px;
      position: relative;
      padding: 37px 102px 56px 103px;
      .img {
        margin-top: 40px;
        width: 100%;
        img {
          width: 100%;
        }
      }
      .item_left {
        position: absolute;
        top: 555px;
        left: 130px;
        .item_title {
          color: #fff;
          font-size: 30px;
          font-weight: 700;
          line-height: 43.44px;
        }
        .item_info {
          width: 509px;
          height: 52px;
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          line-height: 26.06px;
          text-shadow: 0px 2px 4px #000000;
        }
      }
      .item_top {
        position: absolute;
        top: 270px;
        left: 727px;
        .item_title {
          color: #fff;
          font-size: 30px;
          font-weight: 700;
          line-height: 43.44px;
        }
        .item_info {
          width: 509px;
          height: 52px;
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          line-height: 26.06px;
          text-shadow: 0px 2px 4px #000000;
        }
      }
      .item_bottom {
        position: absolute;
        top: 555px;
        left: 727px;
        font-size: 25px;
        font-weight: 700;
        line-height: 36.2px;
        color: #ffffff;
      }
    }
  }
  .zh_AIAlgorithm {
    height: 682px;
    background: url(../../../assets/AI智能识别算法/生鲜算法.png) no-repeat
      center;
    background-size: cover;
    padding-top: 37px;
  }
  .en_AIAlgorithm {
    height: 682px;
    background: url(../../../assets/AI智能识别算法/生鲜算法en.png) no-repeat
      center;
    background-size: cover;
    padding-top: 37px;
  }
  .video {
    width: 100%;
    height: 793px;
    video {
      object-fit: cover;
      object-position: 50% 60%;
      width: 100%;
      height: 100%;
    }
  }
  .zh_AISnack {
    height: 650px;
    background: url(../../../assets/AI智能识别算法/零食算法.png) no-repeat
      center;
    background-size: cover;
    padding-top: 37px;
  }
  .en_AISnack {
    height: 650px;
    background: url(../../../assets/AI智能识别算法/零食算法en.png) no-repeat
      center;
    background-size: cover;
    padding-top: 37px;
  }
  .zh_AIBaking {
    height: 519px;
    background: url(../../../assets/AI智能识别算法/烘培算法.png) no-repeat
      center;
    background-size: cover;
    padding-top: 37px;
  }
  .en_AIBaking {
    height: 519px;
    background: url(../../../assets/AI智能识别算法/烘培算法en.png) no-repeat
      center;
    background-size: cover;
    padding-top: 37px;
  }
}
</style>
